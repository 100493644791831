import React, { useEffect, useCallback } from "react"
import styled from "@emotion/styled"
import { Global, css } from "@emotion/core"
import { Trans } from "@lingui/macro"
import { useSpring } from "react-spring"

import SEO from "../components/SEO"
import Grid from "../components/Home"
import { breakpoints } from "../utils/styles"

const IndexPage = () => {
  const [{ scroll }, set] = useSpring(() => ({ scroll: 0 }))
  const onScroll = useCallback(
    e => void set({ scroll: (e - 600) / (window.innerHeight / 3.5) }),
    []
  )
  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  function handleScroll() {
    onScroll(window.pageYOffset)
    return
  }

  return (
    <>
      <SEO
        title="Language Guide | Learn Travel Vocabulary"
        keywords={[`Language Guide`, `travel`, `phrases`, `language`]}
        isHome={true}
      />
      <Global
        styles={css`
          html {
            scroll-behavior: smooth;
          }
        `}
      />
      <Hero>
        <h1>
          <Trans>Travel Anywhere</Trans>
        </h1>
      </Hero>
      <Grid offset={scroll} />
    </>
  )
}

const Hero = styled.div`
  height: calc(100vh - 110px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: #fff;

  h1 {
    font-size: 2.5em;
    font-weight: normal;
    margin-top: -28vh;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    h1 {
      font-size: 3.6em;
    }
  }
`

export default IndexPage
