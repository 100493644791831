import React, { useState, useContext } from "react"
import styled from "@emotion/styled"

import Search from "./Search"
import GridItem from "./GridItem"
import { UIContext } from "../../context/UIContext"

import { langs } from "../../data/snippets"
import { breakpoints, fonts } from "../../utils/styles"
import useSort from "../../utils/sort"

const Grid = ({ offset }) => {
  const { state } = useContext(UIContext)
  const { sortedData, setSortKey } = useSort(langs, "name")
  const [results, setResults] = useState(null)
  const [search, toggleSearch] = useState(false)

  // Sort & Search Functions
  const sort = (desc = true) => {
    return setSortKey("name")
  }

  const popular = type => {
    toggleSearch(false)
    return setSortKey("rank")
  }

  const searchQuery = result => {
    toggleSearch(true)
    return setResults(result)
  }

  // TODO - Animate results

  function renderGrid() {
    if (search && results !== undefined) {
      return <GridItem key={results.name} language={results} />
    } else if (search) {
      // TODO - Add funny GIF array here
      return <NoResults>Sorry, we couldn't find any results.</NoResults>
    }

    return sortedData
      .filter(l => l.code !== state.baseLang.code)
      .map(o => <GridItem key={o.name} language={o} />)
  }

  return (
    <>
      <Search
        offset={offset}
        result={searchQuery}
        reset={popular}
        sort={sort}
        heroHeight={state.heroHeight}
      />
      <LanguageGrid height={state.heroHeight}>{renderGrid()}</LanguageGrid>
    </>
  )
}

// Styled Components

const LanguageGrid = styled.div`
  padding: 100px 0;
  display: grid;
  margin: auto;
  margin-top: ${({ height }) => height / 2}px;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  min-height: 75vh;
  max-width: 1240px;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));

  @media (min-width: ${breakpoints.tablet}px) {
    grid-column-gap: 50px;
    grid-row-gap: 60px;
    min-height: 75vh;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }
`

const NoResults = styled.div`
  grid-column: span 5;
  text-align: center;
  padding-top: 50px;
  font-size: 1.3em;
  font-family: ${fonts.heading};
`

export default Grid
