import React, { useState, useRef, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { Trans } from "@lingui/macro"
import { animated as a, useSpring } from "react-spring"

import { UIContext } from "../../context/UIContext"

import { langs } from "../../data/snippets"
import { reverse } from "../../utils/helper"
import {
  CloseIcon,
  SortIcon,
  PopularIcon,
  SearchIcon,
} from "../../assets/icons"
import { breakpoints } from "../../utils/styles"

const Search = ({ result, reset, sort, heroHeight, offset }) => {
  const { state } = useContext(UIContext)
  const suggestions = langs.map(c => c.name)
  const searchRef = useRef(null)

  const [active, setActive] = useState(0)
  const [filtered, setFiltered] = useState([])
  const [suggestionsView, showSuggestions] = useState(false)
  const [input, setInput] = useState("")
  const [scrolled, setScroll] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [scrolled])

  function handleScroll() {
    if (!scrolled && window.pageYOffset > heroHeight - 105) {
      setScroll(true)
    }

    if (scrolled && window.pageYOffset < heroHeight - 105) {
      setScroll(false)
    }

    return
  }

  // Search
  const matchQuery = e => {
    const language = langs.find(o => o.name.toLowerCase() === e.toLowerCase())

    window.scroll({ top: heroHeight, left: 0, behavior: "smooth" })

    if (language !== undefined) {
      return result(language)
    }
    return result(undefined)
  }

  function updateState(active, filtered, show, input, resetSearch = false) {
    setActive(active)
    setFiltered(filtered)
    showSuggestions(show)
    setInput(input)

    if (resetSearch) {
      return reset(true)
    }
  }

  const onChange = e => {
    const userInput = e.currentTarget.value
    const filteredSuggestions = suggestions.filter(
      s => s.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    )

    updateState(0, filteredSuggestions, true, e.currentTarget.value)
  }

  const onClick = e => {
    updateState(0, [], false, e.currentTarget.innerText)
    matchQuery(e.currentTarget.innerText)
  }

  const onKeyDown = e => {
    switch (e.keyCode) {
      // Enter Key
      case 13:
        e.preventDefault()
        updateState(
          0,
          [...filtered],
          false,
          filtered.length ? filtered[active] : e.currentTarget.value
        )
        matchQuery(filtered.length ? filtered[active] : e.currentTarget.value)
        break
      // Up Arrow
      case 38:
        return active === 0 ? null : setActive(active - 1)
      // Down Arrow
      case 40:
        return active - 1 === filtered.length ? null : setActive(active + 1)
      default:
        break
    }
  }

  function renderSuggestions() {
    if (suggestionsView && input && filtered.length) {
      return (
        <SuggestionList>
          {filtered.map((suggestion, index) => (
            <li
              className={index === active ? "active-suggestion" : ""}
              key={suggestion}
              onClick={onClick}
              tabindex="0"
              onKeyDown={onKeyDown}
            >
              <Trans id={suggestion} />
            </li>
          ))}
        </SuggestionList>
      )
    }
  }

  const revealProps = useSpring({
    to: reverse(input, [
      { visibility: input ? "visible" : "hidden" },
      { opacity: input ? 1 : 0 },
    ]),
    from: { opacity: 0, visibility: "hidden" },
    config: { friction: 15 },
  })

  const inputProps = useSpring({
    boxShadow: scrolled
      ? `1px 1px 20px rgba(0, 0, 0, 0)`
      : `1px 1px 20px rgba(0, 0, 0, 0.15)`,
    borderColor: scrolled ? `#dcdcdc` : `rgba(220, 220, 220, 0)`,
    // config: { friction: 18 },
  })

  const buttonProps = useSpring({
    to: reverse(scrolled, [
      { visibility: scrolled ? "visible" : "hidden" },
      { opacity: scrolled ? 1 : 0 },
    ]),
    from: { opacity: 0, visibility: "hidden" },
    config: { friction: 15 },
  })

  const [opacity] = useState(() => offset.interpolate([0, 1], [0, 1]))

  return (
    <SearchBar ref={searchRef}>
      <SearchBG style={{ opacity }} />

      <SearchContainer className={scrolled ? "scrolled" : ""}>
        <IconBox>
          <SearchIcon />
        </IconBox>
        <a.input
          type="text"
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={input}
          style={inputProps}
          placeholder={
            state.baseLang.code === `en`
              ? `Search for a language`
              : `搜索一种语言`
          }
        />
        {renderSuggestions()}
        <ClearButton
          aria-label="Clear search"
          style={revealProps}
          onClick={() => updateState("", [], false, "", true)}
        >
          <CloseIcon />
        </ClearButton>
      </SearchContainer>
      <ButtonGroup style={buttonProps}>
        <button onClick={reset}>
          <PopularIcon />
          <Trans>Popular</Trans>
        </button>
        <button onClick={sort}>
          <SortIcon />
          <Trans>Sort</Trans>
        </button>
      </ButtonGroup>
    </SearchBar>
  )
}

// Styled Components
const ClearButton = styled(a.button)`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  border: none;
  background: #fbfbfb;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 5px;
  padding: 0 15px;
  display: flex;
  border-left: 1px solid #dcdcdc;
  transition: background ease 0.3s;

  svg {
    height: 35px;
    fill: #949494;
    // margin-top: -2px;
    transition: fill ease 0.3s;
  }
`

const SearchBar = styled(a.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  padding: 15px 25px;
  z-index: 10;
  margin-top: -50vh;

  input {
    width: 100%;
    height: 65px;
    border-radius: 5px;
    border: 1px solid;
    margin-right: 10px;
    font-size: 19px;
    padding: 0 30px 0 70px;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.3);
  }

  .scrolled button {
    background: none;
  }
`

const SearchContainer = styled.div`
  flex: 1;
  position: relative;
  max-width: 480px;
`

const ButtonGroup = styled(a.div)`
  z-index: 2;
  display: none;
  position: absolute;
  right: 5%;

  button {
    border: 1px solid gainsboro;
    border-radius: 3px;
    background: white;
    padding: 0 20px;
    height: 65px;
    margin: 5px;
    display: flex;
    align-items: center;
    transition: background ease 0.3s;

    &:hover {
      background: #eaeaea;
    }
  }

  svg {
    height: 24px;
    width: auto;
    margin-right: 10px;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    display: flex;
  }
`

const SearchBG = styled(a.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.13) 0px 1px 2px;
  top: 0;
  left: 0;
  background: rgb(248, 248, 248);
`

const IconBox = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 32px;
    margin: 2px 20px 0;
    fill: #636363;
  }
`

const SuggestionList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid gainsboro;
  position: absolute;
  margin-top: -3px;
  width: 100%;
  z-index: 1;
  background: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 1px 7px 8px 1px rgba(0, 0, 0, 0.15);

  li {
    padding: 15px;
    margin: 0;
    font-size: 17px;
    cursor: pointer;
  }

  li:not(:last-of-type) {
    border-bottom: 1px solid gainsboro;
  }

  .active-suggestion,
  li:hover {
    background: #f7f7f7;
    font-weight: bold;
  }
`

Search.propTypes = {
  result: PropTypes.func,
  reset: PropTypes.func,
  sort: PropTypes.func,
  heroHeight: PropTypes.number,
}

Search.defaultProps = {
  result: () => {},
  reset: () => {},
  sort: () => {},
  heroHeight: 0,
}

export default Search
