import { useState } from "react"

function dynamicSort(property) {
  var sortOrder = 1

  if (property[0] === "-") {
    sortOrder = -1
    property = property.substr(1)
  }

  if (property === "name") {
    return (a, b) => {
      if (sortOrder === -1) {
        if (isNaN(property)) {
          return b[property].localeCompare(a[property])
        }
      } else {
        if (isNaN(property)) {
          return a[property].localeCompare(b[property])
        }
      }
    }
  } else {
    return (a, b) => a.property - b.property
  }
}

// Custom Sort Hook

const useSort = (inputArray, initialSortKey) => {
  const [state, setState] = useState({
    isAscending: false,
    sortKey: initialSortKey,
  })

  const sortedData = inputArray.slice().sort(dynamicSort(state.sortKey))

  return {
    ...state,
    sortedData,
    toggleAscending: () =>
      setState(state => ({
        ...state,
        isAscending: !state.isAscending,
      })),
    setSortKey: sortKey => setState(state => ({ ...state, sortKey })),
  }
}

export default useSort
