import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import { Trans } from "@lingui/macro"

import { fonts, breakpoints } from "../../utils/styles"

const GridItem = ({ language }) => {
  return (
    <GridLink to={`/${language.path}`}>
      <Tile color={language.color}>
        <span>{language.code}</span>
      </Tile>
      <h4>
        <Trans id={language.name} />
      </h4>
    </GridLink>
  )
}

const GridLink = styled(Link)`
  display: flex;
  align-items: center;
  flex-direction: column;

  h4 {
    font-size: 1.1em;
    color: #212121;
    margin: 0;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 1.45em;
  }
`

const Tile = styled.div`
  position: relative;
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.12);
  border-radius: 7px;
  height: 75px;
  width: 75px;

  background: ${({ color }) => color};
  font-weight: bold;
  display: flex;
  align-items: center;
  color: black;
  font-family: ${fonts.heading};
  font-size: 2em;
  justify-content: center;
  margin-bottom: 15px;
  transition: all ease 0.3s;

  span {
    z-index: 1;
  }

  &:before {
    content: "";
    background: #ffffff;
    position: absolute;
    left: 5px;
    top: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 3px;
  }

  &:hover {
    transform: scale(1.1);
    box-shadow: 1px 1px 10px 5px rgba(0, 0, 0, 0.1);
  }

  @media (min-width: ${breakpoints.tablet}px) {
    height: 105px;
    width: 105px;
    margin-bottom: 25px;
    font-size: 1.9em;
  }
`

GridItem.propTypes = {
  language: PropTypes.object,
}

GridItem.defaultProps = {
  language: {},
}

export default GridItem
